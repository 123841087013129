import React,{useState,useEffect} from 'react'
import "./utente.css"
import axios from "axios"

const Utente = ({serverURL,utente}) => {
  document.title="Profile | Gita"
  const delet =()=>{
    axios.put(serverURL+"delete",{
      email:utente.email,
      password:utente.password,
    },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
        if(e.status===200){
          document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie = "password=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          window.location.href="/"
        }else{
          alert("Error: "+e.data)
        }
    })
  }
  useEffect(()=>{
    const timer = setTimeout(()=>{
      if(!document.cookie.split(";").find(i=>i.includes("email"))&&!document.cookie.split(";").find(i=>i.includes("password"))){
        window.location.href="/login"
      }else{
        if(utente===203){
          document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie = "password=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        }
      }
    },1000);
    return ()=>clearTimeout(timer);
  },[]);
  const [suggest,setSuggest]=useState()
  function suggestCities(inputText){
    setSuggest()
    fetch(`https://nominatim.openstreetmap.org/search?city=${inputText}&format=json`)
      .then(response => response.json())
      .then(data =>{
        setSuggest(data)
      })
      .catch(error => {
        setSuggest("<div class='error'>Errore durante il recupero dei suggerimenti</div>");
      });
  }
  function sendBoard(){
    axios.put(serverURL+"board",{
      email:utente.email,
      password:utente.password,
      citta:document.getElementById("cityInput").value,
      dataBoard:document.getElementById("dateInput").value,
      text:document.getElementById("textInput").value,
      data:new Date()
    },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
        if(e.status===200){
          window.location.reload()
        }else{
          alert("Error: "+e.data)
        }
    })
  }
  return (
    <div className='utente'>
      <div className='utente-title'>
        <h1>Profile</h1>
      </div>
      <br />
      {utente?<div className='utente-content'>
        <div className='utente-content-info'>
          <div className='utente-content-info-item'>
            <svg viewBox="0 0 16 16">
              <path d="M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914"/>
            </svg>
            <div>Email: <br /><big>{utente.email}</big></div>
          </div>
          <div className='utente-content-info-item'>
            <svg viewBox="0 0 16 16">
              <path d="M8.06 6.5a.5.5 0 0 1 .5.5v.776a11.5 11.5 0 0 1-.552 3.519l-1.331 4.14a.5.5 0 0 1-.952-.305l1.33-4.141a10.5 10.5 0 0 0 .504-3.213V7a.5.5 0 0 1 .5-.5Z"/>
              <path d="M6.06 7a2 2 0 1 1 4 0 .5.5 0 1 1-1 0 1 1 0 1 0-2 0v.332q0 .613-.066 1.221A.5.5 0 0 1 6 8.447q.06-.555.06-1.115zm3.509 1a.5.5 0 0 1 .487.513 11.5 11.5 0 0 1-.587 3.339l-1.266 3.8a.5.5 0 0 1-.949-.317l1.267-3.8a10.5 10.5 0 0 0 .535-3.048A.5.5 0 0 1 9.569 8m-3.356 2.115a.5.5 0 0 1 .33.626L5.24 14.939a.5.5 0 1 1-.955-.296l1.303-4.199a.5.5 0 0 1 .625-.329"/>
              <path d="M4.759 5.833A3.501 3.501 0 0 1 11.559 7a.5.5 0 0 1-1 0 2.5 2.5 0 0 0-4.857-.833.5.5 0 1 1-.943-.334m.3 1.67a.5.5 0 0 1 .449.546 10.7 10.7 0 0 1-.4 2.031l-1.222 4.072a.5.5 0 1 1-.958-.287L4.15 9.793a9.7 9.7 0 0 0 .363-1.842.5.5 0 0 1 .546-.449Zm6 .647a.5.5 0 0 1 .5.5c0 1.28-.213 2.552-.632 3.762l-1.09 3.145a.5.5 0 0 1-.944-.327l1.089-3.145c.382-1.105.578-2.266.578-3.435a.5.5 0 0 1 .5-.5Z"/>
              <path d="M3.902 4.222a5 5 0 0 1 5.202-2.113.5.5 0 0 1-.208.979 4 4 0 0 0-4.163 1.69.5.5 0 0 1-.831-.556m6.72-.955a.5.5 0 0 1 .705-.052A4.99 4.99 0 0 1 13.059 7v1.5a.5.5 0 1 1-1 0V7a3.99 3.99 0 0 0-1.386-3.028.5.5 0 0 1-.051-.705M3.68 5.842a.5.5 0 0 1 .422.568q-.044.289-.044.59c0 .71-.1 1.417-.298 2.1l-1.14 3.923a.5.5 0 1 1-.96-.279L2.8 8.821A6.5 6.5 0 0 0 3.058 7q0-.375.054-.736a.5.5 0 0 1 .568-.422m8.882 3.66a.5.5 0 0 1 .456.54c-.084 1-.298 1.986-.64 2.934l-.744 2.068a.5.5 0 0 1-.941-.338l.745-2.07a10.5 10.5 0 0 0 .584-2.678.5.5 0 0 1 .54-.456"/>
              <path d="M4.81 1.37A6.5 6.5 0 0 1 14.56 7a.5.5 0 1 1-1 0 5.5 5.5 0 0 0-8.25-4.765.5.5 0 0 1-.5-.865m-.89 1.257a.5.5 0 0 1 .04.706A5.48 5.48 0 0 0 2.56 7a.5.5 0 0 1-1 0c0-1.664.626-3.184 1.655-4.333a.5.5 0 0 1 .706-.04ZM1.915 8.02a.5.5 0 0 1 .346.616l-.779 2.767a.5.5 0 1 1-.962-.27l.778-2.767a.5.5 0 0 1 .617-.346m12.15.481a.5.5 0 0 1 .49.51c-.03 1.499-.161 3.025-.727 4.533l-.07.187a.5.5 0 0 1-.936-.351l.07-.187c.506-1.35.634-2.74.663-4.202a.5.5 0 0 1 .51-.49"/>
            </svg>
            <div>Password: <br /><big>{utente.password}</big></div>
          </div>
          <div className='utente-content-info-item'>
            <svg viewBox="0 0 16 16">
              <path d="m2.244 13.081.943-2.803H6.66l.944 2.803H8.86L5.54 3.75H4.322L1 13.081zm2.7-7.923L6.34 9.314H3.51l1.4-4.156zm9.146 7.027h.035v.896h1.128V8.125c0-1.51-1.114-2.345-2.646-2.345-1.736 0-2.59.916-2.666 2.174h1.108c.068-.718.595-1.19 1.517-1.19.971 0 1.518.52 1.518 1.464v.731H12.19c-1.647.007-2.522.8-2.522 2.058 0 1.319.957 2.18 2.345 2.18 1.06 0 1.716-.43 2.078-1.011zm-1.763.035c-.752 0-1.456-.397-1.456-1.244 0-.65.424-1.115 1.408-1.115h1.805v.834c0 .896-.752 1.525-1.757 1.525"/>
            </svg>
            <div>Name/Surname: <br /><big>{utente.nome}</big></div>
          </div>
          <div className='utente-content-info-item'>
            <svg viewBox="0 0 16 16">
              <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708z"/>
            </svg>
            <div>Profession: <br /><big>{utente.professione}</big></div>
          </div>
          <div className='utente-content-info-item'>
            <svg viewBox="0 0 16 16">
              <path d="M6.445 11.688V6.354h-.633A13 13 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23"/>
              <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
            </svg>
            <div>Registration Date: <br /><big>{new Date(utente.data).getFullYear()}-{new Date(utente.data).getMonth()+1}-{new Date(utente.data).getDate()}</big></div>
          </div>
        </div>
        <br />
        <div className='utente-content-board'>
          <div className='utente-content-board-title'>
            <h2>Board Diary</h2>
          </div>
          <form className='utente-content-board-inputs' onSubmit={(e)=>{
            e.preventDefault()
            sendBoard()
          }}>
            <div className='utente-content-board-inputs-item'>
              <input type="text" id="cityInput" onKeyUp={(e)=>e.target.value.length>2?suggestCities(e.target.value):setSuggest()} placeholder="City..." required/>
              {suggest&&<div className='utente-content-board-inputs-item-suggest'>
                {suggest?suggest.map((item,n)=>{
                  return(
                    <div className='utente-content-board-inputs-item-suggest-item' key={n} onClick={(e)=>{
                      document.getElementById("cityInput").value=e.target.textContent+";lat:"+item.lat+",lon:"+item.lon
                      setSuggest()
                    }}>
                      <div>{item.display_name}</div>
                    </div>
                  )
                }):""}
              </div>}
            </div>
            <div className='utente-content-board-inputs-item'>
              <input type="date" id="dateInput" required/>
            </div>
            <div className='utente-content-board-inputs-item'>
              <textarea type="text" rows={4} id="textInput" placeholder='Write your experience...' required/>
            </div>
            <div className='utente-content-board-inputs-item'>
              <button>Send</button>
            </div>
          </form>
          <br />
          <br />
          <div className='utente-content-board-title'>
            <h3>My Memories</h3>
          </div>
          <div className='utente-content-board-list'>
            {utente.board&&utente.board.length>0&&utente.board.map((item,n)=>{
              return(
                <div key={n} className='utente-content-board-list-item'>
                  <div><big>{item.citta.split(";")[0]} {item.dataBoard}</big></div>
                  <div><a href={"https://www.google.com/maps/search/?api=1&query="+item.citta.split(";")[1].split(",")[0].split(":")[1]+','+item.citta.split(";")[1].split(",")[1].split(":")[1]} target='_blank' rel="noreferrer">Directions</a></div>
                  <br />
                  <div style={{color:"#888"}}>{item.text}</div>
                  <br />
                  <small>{new Date(item.data).getFullYear()}-{new Date(item.data).getMonth()+1}-{new Date(item.data).getDate()}/{new Date(item.data).getHours()}:{new Date(item.data).getMinutes()}</small>
                </div>
              )
            })}
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className='utente-content-button'>
          <div className='utente-content-button-item'>
            <button onClick={()=>{
              document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              document.cookie = "password=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              window.location.href="/"
            }}>Logout</button>
          </div>
          <div className='utente-content-button-item'>
            <button onClick={()=>delet()}>Delete Profile</button>
          </div>
        </div>
        <br />
      </div>:<div className='lds'><div className='lds-c'><div className="lds-spinner"></div><div className='fade-text-container'><p class="fade-text">Loading data from servers...</p><p class="fade-text">Translation of content into the selected language...</p><p class="fade-text">Preparing your personalized user experience...</p><p class="fade-text">Please wait thanks...</p></div></div></div>}
    </div>
  )
}

export default Utente