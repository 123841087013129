import React, { useState,useEffect } from 'react'
import "./login.css"
import axios from 'axios'
import img from "./logo.jpg"


const Login = ({serverURL}) => {
    document.title="Login | Gita"
    const [caricamento,setCaricamento]=useState(false)
    const replaceSpaces=()=>{
        document.getElementById("nome").value=document.getElementById("nome").value.replace(/ /g, "/")
    }
    const signup=()=>{
        setCaricamento(true)
        axios.put(serverURL+"signup",{
            email:document.getElementById("email").value,
            password:document.getElementById("password").value,
            nome:document.getElementById("nome").value.replace("/"," "),
            professione:document.getElementById("professione").value,
            nascita:document.getElementById("nascita").value,
            data:new Date()
        },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
            setCaricamento(false)
            if(e.status===200){
                console.log(e.data);
                document.cookie="email="+e.data.email
                document.cookie="password="+e.data.password
                if(window.matchMedia('(display-mode: standalone)').matches){
                    window.location.href="/"
                }else{
                    window.location.href="/explore"
                }
            }else{
                alert("Signup Error: "+e.data)
            }
          })
    }
    const login=()=>{
        setCaricamento(true)
        axios.put(serverURL+"login",{
            email:document.getElementById("email").value,
            password:document.getElementById("password").value,
        },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
            setCaricamento(false)
            if(e.status===200){
              document.cookie="email="+e.data.email
              document.cookie="password="+e.data.password
              if(window.matchMedia('(display-mode: standalone)').matches){
                window.location.href="/"
              }else{
                window.location.href="/explore"
              }
            }else{
              alert("Login Error: "+e.data)
            }
        })
    }
    useEffect(()=>{
        const timer=setTimeout(()=>{
            if(document.cookie.split(";").find(i=>i.includes("email"))&&document.cookie.split(";").find(i=>i.includes("password"))){
                window.location.href="/profile"
            }
        },1000);
        return ()=>clearTimeout(timer);
    },[]);
  return (
    <div className='login'>
        {caricamento&&<div className='lds'><div className='lds-c'><div className="lds-spinner"></div><div className='fade-text-container'><p class="fade-text">Loading data from servers...</p><p class="fade-text">Translation of content into the selected language...</p><p class="fade-text">Preparing your personalized user experience...</p><p class="fade-text">Please wait thanks...</p></div></div></div>}
        <div className='login-title'>
            <h1>Login / Signup</h1>
        </div>
        <br />
        <div className='login-content'>
            {window.location.search.substring(1).split("=")[1]==="true"&&window.location.search.substring(1).split("=")[0]==="signup"?<form className='login-content-form' onSubmit={(e)=>{
                e.preventDefault()
                signup()
            }}>
                <div style={{width:"100px"}}>
                    <img style={{width:"100%",borderRadius:"5px"}} src={img} alt="logo" />
                </div>
                <br />
                <br />
                <div className='login-content-form-item'>
                    <svg viewBox="0 0 16 16">
                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z"/>
                    </svg>
                    <input type="email" id='email' placeholder='Email...' required/>
                </div>
                <div className='login-content-form-item'>
                    <svg viewBox="0 0 16 16">
                    <path d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2M2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
                    </svg>
                    <input type="password" id='password' placeholder='Password...' required/>
                </div>
                <div className='login-content-form-item'>
                    <svg viewBox="0 0 16 16">
                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                    </svg>
                    <input type="text" id='nome' onInput={()=>replaceSpaces()} placeholder='Name/Surname...' required/>
                </div>
                <div className='login-content-form-item'>
                    <svg viewBox="0 0 16 16">
                        <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
                    </svg>
                    <select id="professione" required>
                        <option value="Healthcare and Assistance">Healthcare and Assistance</option>
                        <option value="Legal">Legal</option>
                        <option value="Education and Instruction">Education and Instruction</option>
                        <option value="Information Technology">Information Technology</option>
                        <option value="Engineering and Architecture">Engineering and Architecture</option>
                        <option value="Commerce and Finance">Commerce and Finance</option>
                        <option value="Art and Design">Art and Design</option>
                        <option value="Science and Research">Science and Research</option>
                        <option value="Production and Manufacturing">Production and Manufacturing</option>
                        <option value="Marketing and Comunication">Marketing and Comunication</option>
                        <option value="Student">Student</option>
                    </select>
                </div>
                <div className='login-content-form-item'>
                    <svg viewBox="0 0 16 16">
                        <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857z"/>
                        <path d="M12 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
                    </svg>
                    <input type="date" id='nascita' required/>
                </div>
                <br />
                <div className='login-content-form-item'>
                    <button>Send</button>
                </div>
                <br />
                <div className='login-content-form-small'>
                    <div onClick={()=>window.location.href=window.location.pathname}>Login</div>
                </div>
            </form>:<form className='login-content-form' onSubmit={(e)=>{
                e.preventDefault()
                login()
            }}>
                <div style={{width:"100px"}}>
                    <img style={{width:"100%",borderRadius:"5px"}} src={img} alt="logo" />
                </div>
                <br />
                <br />
                <div className='login-content-form-item'>
                    <svg viewBox="0 0 16 16">
                        <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z"/>
                    </svg>
                    <input type="email" id='email' placeholder='Email...' required/>
                </div>
                <div className='login-content-form-item'>
                    <svg viewBox="0 0 16 16">
                    <path d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2M2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
                    </svg>
                    <input type="password" id='password' placeholder='Password...' required/>
                </div>
                <br />
                <div className='login-content-form-item'>
                    <button>Send</button>
                </div>
                <br />
                <div className='login-content-form-small'>
                    <div onClick={()=>window.location.href=window.location.pathname+"?signup=true"}>Don't You Have an Account? Signup</div>
                </div>
            </form>}
        </div>
        <br />
        <br />
    </div>
  )
}

export default Login