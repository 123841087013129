import React from 'react'
import "./navbar.css"
import logo from "./logo.jpg"

const Navbar = ({utente}) => {
  return (
    <nav className='navbar' id='navbar'>
      <div className='navbar-menu'>
        {!window.matchMedia('(display-mode: standalone)').matches&&<div className='navbar-menu-menu' onClick={()=>document.getElementById("navbar-list").style.transform="translateX(0%)"}>
          <svg viewBox="0 0 16 16">
            <path d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
          </svg>
        </div>}
        <div onClick={()=>window.location.href="/"}><img src={logo}/></div>
      </div>
      {!document.cookie.split(";").find(i=>i.includes("email"))&&!document.cookie.split(";").find(i=>i.includes("password"))?<div className='navbar-item' onClick={()=>window.location.href="/login"} style={{fontSize:"18px",background:"white",color:"black",margin:"0 5px",padding:"5px 10px",borderRadius:"6px",fontWeight:"700"}}>
        <div>Login</div>
      </div>:<div className='navbar-item' onClick={()=>window.location.href="/utente"}>
        <svg viewBox="0 0 16 16" style={{fill:"white"}}>
          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
          <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
        </svg>
        {utente&&<small style={{color:"white"}}>{utente.nome}</small>}
      </div>}
      {!window.matchMedia('(display-mode: standalone)').matches&&<div className='navbar-list' id='navbar-list'>
        <div className='navbar-exit' onClick={()=>document.getElementById("navbar-list").style.transform="translateX(-101%)"}>
          <svg viewBox="0 0 16 16" style={{fill:'var(--red)'}}>
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
          </svg>
        </div>
        <div className='navbar-item' onClick={()=>window.location.href="/"}>
          <svg viewBox="0 0 16 16">
            <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z"/>
          </svg>
          <div>Home</div>
        </div>
        <div className='navbar-item' onClick={()=>window.location.href="/explore"}>
          <svg viewBox="0 0 16 16">
            <path d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm4.5 5.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
          </svg>
          <div>Start Now</div>
        </div>
        <div className='navbar-item' onClick={()=>window.location.href="/who"}>
          <svg viewBox="0 0 16 16">
            <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022M6 8.694 1 10.36V15h5zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5z"/>
            <path d="M2 11h1v1H2zm2 0h1v1H4zm-2 2h1v1H2zm2 0h1v1H4zm4-4h1v1H8zm2 0h1v1h-1zm-2 2h1v1H8zm2 0h1v1h-1zm2-2h1v1h-1zm0 2h1v1h-1zM8 7h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zM8 5h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zm0-2h1v1h-1z"/>
          </svg>
          <div>Who</div>
        </div>
        <div className='navbar-item' onClick={()=>window.location.href="/contacts"}>
          <svg viewBox="0 0 16 16">
            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
          </svg>
          <div>Contacts</div>
        </div>
        <div className='navbar-item' onClick={()=>window.location.href="/login"}>
          <div>Login</div>
        </div>
      </div>}
    </nav>
  )
}

export default Navbar