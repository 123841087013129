import React,{useEffect,useState,useRef} from 'react'
import "./start.css"
import axios from 'axios';
import {MapContainer,TileLayer,Popup,ScaleControl,useMap,useMapEvent,Marker} from 'react-leaflet';
import "leaflet/dist/leaflet.css"
import 'leaflet-geosearch/dist/geosearch.css';
import 'leaflet.locatecontrol'
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css'
import 'leaflet.markercluster';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import L from 'leaflet';
import {GeoSearchControl, OpenStreetMapProvider} from 'leaflet-geosearch';

const Start = ({serverURL,utente}) => {
  document.title="Explore | Gita"

  const [citta,setCitta]=useState()
  const [coords,setCoords]=useState()
  const [view,setView]=useState()
  const [result,setResult]=useState()
  const [info,setInfo]=useState()
  const [caricamento,setCaricamento]=useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState('it')
  async function findAttractionBounds(latNe,lonNe,latSw,lonSw){
    axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${(latNe+latSw)/2}&lon=${(lonNe+lonSw)/2}&format=json`).then(e=>setCitta(e.data)).catch(err=>alert("Error: "+err))
    setCaricamento(true)
    await axios.put(serverURL+"wikiBound",{
      latNe:latNe,lonNe:lonNe,latSw:latSw,lonSw:lonSw,
      raggio:"area",
    },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
      setCaricamento(false)
      if(e.status===200){
        if(e.data.length>0){
          setResult(e.data)
        }else{
          setResult()
        }
      }else{
        alert("Error: "+e.data)
      }
    }).catch(err=>alert("Error: "+err))
  }
  //ottieni i testi per ogni attrazione
  const wikiText=async(nome,wikipedia,wikidata,lat,lon,id)=>{
    setInfo()
    setCaricamento(true)
    axios.put(serverURL+"wikiText",{
      nome:nome,
      wikipedia:wikipedia,
      wikidata:wikidata,
      lat:lat,
      lon:lon,
      lingua:document.getElementById("language").value,
    },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
      setCaricamento(false)
      if(e.status===200){
        axios.put(serverURL+"getSubmitToIdWiki",{idWiki:id},{headers:{'Content-Type':'multipart/form-data'}}).then(j=>{
          if(j.status===200){
            setInfo({id:id,nome:nome,info:e.data,submitted:j.data});
          }else{
            setInfo({id:id,nome:nome,info:e.data});
          }
        })
        document.getElementById("resizableDiv-info").style.transform="translateY(0%)"
        document.getElementById("resizableDiv-info").style.opacity="1"
        if(document.getElementById("resizableDiv-info").style.transform="translateY(95%)"){
          document.getElementById("resizableDiv-info").style.transform="translateY(0%)"
        }
      }else{
        alert("Error: "+e.data)
      }
    })
  }
  //leggi il testo
  async function leggiTesto(testo,titolo,x2,y2){
    if(utente){
      setCaricamento(true)
      const audio=document.getElementById("audio")
      audio.scrollIntoView({behavior:"smooth"})
      audio.src=null
      audio.load()
      let distanza
      if(x2&&y2){
        distanza=calcolaDistanza(coords[0],coords[1],x2,y2)
        if(titolo==="In generale"){
          testo="Puoi vederlo alla distanza di "+(distanza).toFixed(1)+". "+testo
        }else if(titolo==="In general"){
          testo="You can see it at the distance of "+(distanza).toFixed(1)+". "+testo
        }
      }
      axios.put(serverURL+"wikiAudio",{
        testo:testo,
        titolo:titolo,
        lingua:document.getElementById("language").value,
        id:utente.nome
      },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
        setCaricamento(false)
        if(e.status===200){
          setTimeout(()=>{
            audio.src=(serverURL+e.data)
            audio.type='audio/mpeg'
            audio.load()
          },500)
        }else{
          alert("Error: "+e.data)
        }
      })
    }else{
      alert("Audio Error: To obtain this attraction point audio you must be registered <a href='/login' target='_blank'>Login</a>.")
    }
  }
  //vedi il testo
  async function vediTesto(testo,titolo,imgs,x2,y2){
    if(utente){
      if(window.confirm("The waiting time is very long, are you sure you want to continue?")){
        setCaricamento(true)
        const audio=document.getElementById("audio")
        audio.scrollIntoView({behavior:"smooth"})
        audio.src=null
        audio.load()
        let distanza
        if(x2&&y2){
          distanza=calcolaDistanza(coords[0],coords[1],x2,y2)
          if(titolo==="In generale"){
            testo="Puoi vederlo alla distanza di "+(distanza).toFixed(1)+". "+testo
          }else if(titolo==="In general"){
            testo="You can see it at the distance of "+(distanza).toFixed(1)+". "+testo
          }
        }
        axios.put(serverURL+"wikiVideo",{
          testo:testo,
          titolo:titolo,
          imgs:imgs,
          lingua:document.getElementById("language").value,
          id:utente.nome
        },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
          setCaricamento(false)
          if(e.status===200){
            setTimeout(()=>{
              audio.src=(serverURL+e.data)
              audio.type='video/mp4'
              audio.load()
            },500)
          }else{
            alert("Error: "+e.data)
          }
        })
      }
    }else{
      alert("Audio Error: To obtain this attraction point audio you must be registered <a href='/login' target='_blank'>Login</a>.")
    }
  }
  useEffect(()=>{
    try {
      axios.get('https://ipinfo.io/json').then((response)=>{
        const data = response.data;
        axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${parseFloat(data.loc.split(',')[0])}&lon=${parseFloat(data.loc.split(',')[1])}&format=json`).then(e=>setCitta(e.data)).catch(err=>alert("Error: "+err))
        //findAttraction(parseFloat(data.loc.split(',')[0]),parseFloat(data.loc.split(',')[1]))
        setView([parseFloat(data.loc.split(',')[0]),parseFloat(data.loc.split(',')[1])])
      });
    } catch(error) {
      setView([45.5357,12.3242])
      alert("Error retrieving location information: "+error);
      return null;
    }
  },[])
  //componente per muovere la div nera e la div bianca dei risultati
  useEffect(()=>{
    //line-info
    if(document.getElementById('line-info')&&document.getElementById('resizableDiv-info')){
      const line = document.getElementById('line-info');
      const resizableDiv = document.getElementById('resizableDiv-info');
      let isDragging = false;
      let startY;
      let lastY;
      line.addEventListener("dblclick", () => {
        setInfo()
        resizableDiv.style.transform="translateY(100%)"
        resizableDiv.style.opacity="0"
      });
      line.addEventListener('mousedown', startDragging);
      line.addEventListener('touchstart', startDragging);
      function startDragging(event) {
        isDragging = true;
        startY = event.clientY||event.touches[0].clientY;
        lastY = startY;
      }
      document.addEventListener('mousemove', drag);
      document.addEventListener('touchmove', drag);
      function drag(event) {
        if (isDragging) {
          let clientY = event.clientY || event.touches[0].clientY;
          let deltaY = clientY - lastY;
          if (deltaY < 0) {
            resizableDiv.style.transform="translateY(0%)"
          } else if (deltaY > 0) {
            resizableDiv.style.transform="translateY(80%)"
          }
          lastY = clientY;
        }
      }
      document.addEventListener('mouseup', stopDragging);
      document.addEventListener('touchend', stopDragging);
      function stopDragging() {
        isDragging = false;
      }
    }
  })
  //calcola distanze e posizione per dirlo nella caregoria "In generale"
  function toRadians(degrees) {
    return degrees * Math.PI / 180;
  }
  function calcolaDistanza(lat1, lon1, lat2, lon2) {
    const R = 6371; // Raggio medio della Terra in chilometri
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distanza in chilometri
    return distance * 1000; // Converti in metri
  }
  const openTextOSummary=(id)=>{
    if(document.getElementById(id).style.height!=="90vh"){
      document.getElementById(id).style.height="90vh"
    }else{
      document.getElementById(id).style.height="100px"
    }
  }
  function calcolaAreaPoligonoGeografico(vertici) {
    const R = 6371; // Raggio medio della Terra in chilometri
    let area = 0;
    const numVertici = vertici.length;
    // Calcoliamo l'area utilizzando l'area di triangoli sferici
    for (let i = 0; i < numVertici; i++) {
        const puntoCorrente = vertici[i];
        const puntoSuccessivo = vertici[(i + 1) % numVertici];
        const lat1 = puntoCorrente.lat;
        const lon1 = puntoCorrente.lon;
        const lat2 = puntoSuccessivo.lat;
        const lon2 = puntoSuccessivo.lon;
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLon = (lon2 - lon1) * (Math.PI / 180);
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        area += R * c;
    }
    return Math.abs(area);
  }
  function calcolaAreaRettangoloGeografico(coordinate) {
    const R = 6371; // Raggio medio della Terra in chilometri
    const lat1 = coordinate[0][0];
    const lon1 = coordinate[0][1];
    const lat2 = coordinate[1][0];
    const lon2 = coordinate[1][1];
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const lato1 = 2 * R * Math.asin(Math.sqrt(Math.pow(Math.sin(dLat / 2), 2) + Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.pow(Math.sin(dLon / 2), 2)));
    // Assumiamo che il lato opposto sia uguale al lato adiacente
    const lato2 = lato1;
    // L'area del rettangolo è il prodotto dei lati
    const area = lato1 * lato2;
    return area;
  }
  function ChangeView(){
    const map=useMap();
    useEffect(()=>{
      map.setView(view)
      const provider = new OpenStreetMapProvider();
      const searchControl = new GeoSearchControl({
        provider: provider,
        autoClose: true,
        autoComplete: true,
        autoCompleteDelay: 250,
      });
      map.addControl(searchControl)
      return () => {
        map.removeControl(searchControl);
      };
    },[map])
    
    if(document.getElementById("radius")&&document.getElementById("radius").value!=="area"){
      map.setView(view)
    }
    useMapEvent("moveend",()=>{
      setView(map.getCenter())
    })
  }
  const [locateActive, setLocateActive] = useState(false);
  const locateControlRef=useRef(null);
  const LocateControl=()=>{
    const map=useMap();
    useEffect(()=>{
      if(locateControlRef.current){
        return;
      }
      const lc=L.control.locate({
        position:'topright',
        setView:'once',
        keepCurrentZoomLevel:true,
        strings:{
          title:"I am here",
        },
        locateOptions:{
          enableHighAccuracy:true,
        },
      });
      lc.addTo(map);
      locateControlRef.current=lc;
    },[map]);
    return null;
  };
  //per dare la possibilità agli utenti di scrivere di una particolare attrazione turistica
  const [write,setWrite]=useState(false)
  const submit=(text,file,idwiki)=>{
    setCaricamento(true)
    axios.put(serverURL+"submitToIdWiki",{
      nome:utente.nome,
      email:utente.email,
      password:utente.password,
      text:text,
      file:file,
      idWiki:idwiki
    },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
        setCaricamento(false)
        if(e.status===200){
          window.location.reload()
        }else{
          alert("Login Error: "+e.data)
        }
    })
  }
  const MarkerCluster = ({children}) => {
    const map = useMap();
    useEffect(() => {
      const markerClusterGroup = L.markerClusterGroup();
      map.addLayer(markerClusterGroup);
      children&&children.forEach((child,n) => {
        if (child && child.props.position) {
          const marker = L.marker(child.props.position);
          if (child.props.children&&child.props.icon){
            marker.setIcon(child.props.icon);
            marker.bindPopup(child.props.children.props.children[0].props.children+"<br/>"+"<small style='color:#0096FF'>"+child.props.children.props.children[1].props.children+"</small><br/>").addEventListener("click",()=>document.getElementById("n"+n).scrollIntoView({behavior:"smooth"}))
          }
          markerClusterGroup.addLayer(marker);
        }
      });
      return () => {
        map.removeLayer(markerClusterGroup);
      };
    },[children, map]);
    return null;
  }
  function GetPosition({selectedLanguage, setSelectedLanguage }){
    const map = useMap();
    const handleButtonClick =()=>{
      findAttractionBounds(map.getBounds().getNorthEast().lat,map.getBounds().getNorthEast().lng,map.getBounds().getSouthWest().lat,map.getBounds().getSouthWest().lng)
    };
    return (
      <div className='start-content-map-button'>
        <div className='start-content-map-button-select'>
          <select id="language" value={selectedLanguage} onChange={(event)=>setSelectedLanguage(event.target.value)}>
            <option value="it">Italiano</option>
            <option value="en">English</option>
            <option value="es">Español</option>
            <option value="fr">Français</option>
            <option value="de">Deutsch</option>
            <option value="nl">Nederlands</option>
            <option value="pt">Português</option>
            <option value="ru">Russkiy</option>
            <option value="zh">Zhōngguó rén (Chinese)</option>
            <option value="ja">Nihongo (Japanese)</option>
          </select>
        </div>
        <button onClick={handleButtonClick}>Search Here</button>
      </div>
    );
  }
  return (
    <div className="start" id='start'>
      {caricamento&&<div className='lds'><div className='lds-c'><div className="lds-spinner"></div><div className='fade-text-container'><p class="fade-text">Loading data from servers...</p><p class="fade-text">Translation of content into the selected language...</p><p class="fade-text">Preparing your personalized user experience...</p><p class="fade-text">Please wait thanks...</p></div></div></div>}
      <div className='start-content'>
        <div className='start-content-map'>
          {view?<MapContainer center={view} zoom={15}>
            <LocateControl activate={locateActive} />
            <TileLayer
              id="mapbox/streets-v11"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              url="https://maps.geoapify.com/v1/tile/klokantech-basic/{z}/{x}/{y}.png?apiKey=d85dfbb4d1db4fcc871b071360faf033"
            />
            <ChangeView center={view}/>
            <GetPosition selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
            <MarkerCluster>
            {result&&result.sort((a,b)=>{
              let areaA=0
              let areaB=0
              if(a.geometry){
                areaA=calcolaAreaPoligonoGeografico(a.geometry)
              }else if(a.lat&&a.lon){
                areaA=0
              }else if(a.bounds){
                areaA=calcolaAreaRettangoloGeografico([[a.bounds.maxlat,a.bounds.maxlon],[a.bounds.minlat,a.bounds.minlon]])
              }
              if(b.geometry){
                areaB=calcolaAreaPoligonoGeografico(b.geometry)
              }else if(b.lat&&b.lon){
                areaB=0
              }else if(b.bounds){
                areaB=calcolaAreaRettangoloGeografico([[b.bounds.maxlat,b.bounds.maxlon],[b.bounds.minlat,b.bounds.minlon]])
              }
              return areaB-areaA
            }).map((item,n)=>{
              if(item.geometry){
                if(item.imageUrl){
                  return(
                    <Marker
                      key={n}
                      position={item.geometry[0]}
                      icon={L.divIcon({
                          className: 'custom-icon',
                          html:  `<div style="display: flex; flex-direction: column; align-items: center;">
                              <img src="${item.imageUrl}" alt="${item.tags.name}" style="width: 50px; height: 50px; border-radius: 50%;" />
                              <span style="background-color: white; padding: 2px 5px; border-radius: 5px; margin-top: 2px; font-size: 8px; text-align: center;">${item.tags.name}</span>
                          </div>`,
                          iconSize: [50, 50],
                          iconAnchor: [25, 25]
                      })}
                    >
                      <Popup>
                        <div style={{cursor:"pointer"}} onClick={()=>document.getElementById("n"+n).scrollIntoView({behavior:"smooth"})}>{item.tags.name}</div>
                        <div style={{color:"var(--red)"}}>{item.tags.tourism||item.tags.historic||item.tags.amenity}</div>
                        <div style={{cursor:"pointer",color:"blue"}} onClick={()=>window.open("https://www.google.com/maps/search/?api=1&query="+item.lat+','+item.lon)}>Directions</div>
                      </Popup>
                    </Marker>
                  )
                }else{
                  return(
                    <Marker
                      key={n}
                      position={item.geometry[0]}
                      icon={L.icon({
                          iconUrl:"https://cdn-icons-png.flaticon.com/512/25/25613.png",
                          iconSize: [30, 30],
                          iconAnchor: [25, 25]
                      })}
                    >
                      <Popup>
                        <div style={{cursor:"pointer"}} onClick={()=>document.getElementById("n"+n).scrollIntoView({behavior:"smooth"})}>{item.tags.name}</div>
                        <div style={{color:"var(--red)"}}>{item.tags.tourism||item.tags.historic||item.tags.amenity}</div>
                        <div style={{cursor:"pointer",color:"blue"}} onClick={()=>window.open("https://www.google.com/maps/search/?api=1&query="+item.lat+','+item.lon)}>Directions</div>
                      </Popup>
                    </Marker>
                  )
                }
              }else if(item.lat&&item.lon){
                if(item.imageUrl){
                  return(
                    <Marker
                      key={n}
                      position={[item.lat, item.lon]}
                      icon={L.divIcon({
                        className: 'custom-icon',
                        html: `<div style="display: flex; flex-direction: column; align-items: center;">
                          <img src="${item.imageUrl}" alt="${item.tags.name}" style="width: 50px; height: 50px; border-radius: 50%;" />
                          <span style="background-color: white; padding: 2px 5px; border-radius: 5px; margin-top: 2px; font-size: 8px; text-align: center;">${item.tags.name}</span>
                        </div>`,
                        iconSize: [50, 50],
                        iconAnchor: [25, 25]
                      })}
                    >
                      <Popup>
                        <div style={{cursor:"pointer"}} onClick={()=>document.getElementById("n"+n).scrollIntoView({behavior:"smooth"})}>{item.tags.name}</div>
                        <div style={{color:"var(--red)"}}>{item.tags.tourism||item.tags.historic||item.tags.amenity}</div>
                        <div style={{cursor:"pointer",color:"blue"}} onClick={()=>window.open("https://www.google.com/maps/search/?api=1&query="+item.lat+','+item.lon)}>Directions</div>
                      </Popup>
                    </Marker>
                  )
                }else{
                  return(
                    <Marker
                      key={n}
                      position={[item.lat, item.lon]}
                      icon={L.icon({
                          iconUrl:"https://cdn-icons-png.flaticon.com/512/25/25613.png",
                          iconSize: [30, 30],
                          iconAnchor: [25, 25]
                      })}
                    >
                      <Popup>
                        <div style={{cursor:"pointer"}} onClick={()=>document.getElementById("n"+n).scrollIntoView({behavior:"smooth"})}>{item.tags.name}</div>
                        <div style={{color:"var(--red)"}}>{item.tags.tourism||item.tags.historic||item.tags.amenity}</div>
                        <div style={{cursor:"pointer",color:"blue"}} onClick={()=>window.open("https://www.google.com/maps/search/?api=1&query="+item.lat+','+item.lon)}>Directions</div>
                      </Popup>
                    </Marker>
                  )
                }
              }else if(item.bounds){
                if(item.imageUrl){
                  return(
                    <Marker
                      key={n}
                      position={[(item.bounds.maxlat+item.bounds.minlat)/2,(item.bounds.maxlon+item.bounds.minlon)/2]}
                      icon={L.divIcon({
                          className: 'custom-icon',
                          html:  `<div style="display: flex; flex-direction: column; align-items: center;">
                              <img src="${item.imageUrl}" alt="${item.tags.name}" style="width: 50px; height: 50px; border-radius: 50%;" />
                              <span style="background-color: white; padding: 2px 5px; border-radius: 5px; margin-top: 2px; font-size: 8px; text-align: center;">${item.tags.name}</span>
                          </div>`,
                          iconSize: [50, 50],
                          iconAnchor: [25, 25]
                      })}
                    >
                      <Popup>
                        <div style={{cursor:"pointer"}} onClick={()=>document.getElementById("n"+n).scrollIntoView({behavior:"smooth"})}>{item.tags.name}</div>
                        <div style={{color:"var(--red)"}}>{item.tags.tourism||item.tags.historic||item.tags.amenity}</div>
                        <div style={{cursor:"pointer",color:"blue"}} onClick={()=>window.open("https://www.google.com/maps/search/?api=1&query="+item.lat+','+item.lon)}>Directions</div>
                      </Popup>
                    </Marker>
                  )
                }else{
                  return(
                    <Marker
                      key={n}
                      position={[(item.bounds.maxlat+item.bounds.minlat)/2,(item.bounds.maxlon+item.bounds.minlon)/2]}
                      icon={L.icon({
                          iconUrl:"https://cdn-icons-png.flaticon.com/512/25/25613.png",
                          iconSize: [30, 30],
                          iconAnchor: [25, 25]
                      })}
                    >
                      <Popup>
                        <div style={{cursor:"pointer"}} onClick={()=>document.getElementById("n"+n).scrollIntoView({behavior:"smooth"})}>{item.tags.name}</div>
                        <div style={{color:"var(--red)"}}>{item.tags.tourism||item.tags.historic||item.tags.amenity}</div>
                        <div style={{cursor:"pointer",color:"blue"}} onClick={()=>window.open("https://www.google.com/maps/search/?api=1&query="+item.lat+','+item.lon)}>Directions</div>
                      </Popup>
                    </Marker>
                  )
                }
              }
            })}
            </MarkerCluster>
            <ScaleControl position="bottomright" />
          </MapContainer>:<div style={{textAlign:"center",marginTop:"35%"}}>
            Loading...
          </div>}
        </div>
        <div id='resizableDiv' className='start-content-results'>
          <div className='start-content-results-scroll'>
            <div className='start-content-results-list'>
              {citta&&<div className='start-content-results-item'>
                <div className='start-content-results-item-svg'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                    <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022M6 8.694 1 10.36V15h5zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5z"/>
                    <path d="M2 11h1v1H2zm2 0h1v1H4zm-2 2h1v1H2zm2 0h1v1H4zm4-4h1v1H8zm2 0h1v1h-1zm-2 2h1v1H8zm2 0h1v1h-1zm2-2h1v1h-1zm0 2h1v1h-1zM8 7h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zM8 5h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zm0-2h1v1h-1z"/>
                  </svg>
                </div>
                <div className='start-content-results-item-text' onClick={()=>wikiText(citta.address.village||citta.address.city||citta.address.town)}>
                  <div className='start-content-results-item-title'>{citta.address.village||citta.address.city||citta.address.town}</div>
                  <small>city</small>
                </div>
              </div>}
              {result?result.sort((a,b)=>{
                let areaA=0
                let areaB=0
                if(a.geometry){
                  areaA=calcolaAreaPoligonoGeografico(a.geometry)
                }else if(a.lat&&a.lon){
                  areaA=0
                }else if(a.bounds){
                  areaA=calcolaAreaRettangoloGeografico([[a.bounds.maxlat,a.bounds.maxlon],[a.bounds.minlat,a.bounds.minlon]])
                }
                if(b.geometry){
                  areaB=calcolaAreaPoligonoGeografico(b.geometry)
                }else if(b.lat&&b.lon){
                  areaB=0
                }else if(b.bounds){
                  areaB=calcolaAreaRettangoloGeografico([[b.bounds.maxlat,b.bounds.maxlon],[b.bounds.minlat,b.bounds.minlon]])
                }
                return areaB-areaA
              }).map((item,n)=>{
                let x2=0
                let y2=0
                if(item.lat&&item.lon){
                  x2=item.lat
                  y2=item.lon
                }else if(item.bounds){
                  x2=(item.bounds.maxlat+item.bounds.minlat)/2
                  y2=(item.bounds.maxlon+item.bounds.minlon)/2
                }
                return(
                  <div id={'n'+n} key={n} className='start-content-results-item'>
                    <div className='start-content-results-item-svg' onClick={()=>setView([x2,y2])}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
                        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                      </svg>
                    </div>
                    <div className='start-content-results-item-text' onClick={()=>wikiText(item.tags.name,item.tags.wikipedia,item.tags.wikidata,x2,y2,item.id)}>
                      <div className='start-content-results-item-title'>{item.tags.name}</div>
                      <small>{item.tags.tourism||item.tags.historic||item.tags.amenity}</small>
                    </div>
                  </div>
                )
              }):!result&&""}
            </div>
          </div>
        </div>
        <div className='start-content-info' id='resizableDiv-info'>
          <div className='line' id='line-info'>
            <div className='line-line'><br/></div>
          </div>
          {info&&citta&&(citta.address.village||citta.address.city||citta.address.town)===info.nome?<div className='start-content-info-content' id='resizableDiv-info-content'>
            <div className='start-content-info-content-title'>
              {info.info[0].img&&info.info[0].img.length>0&&<div className='start-content-info-content-title-img'>
                {info.info[0].img.map((i,n)=>{
                  return(
                    <img key={n} src={i} alt={"img-"+info.nome} />
                  )
                })}
              </div>}
              <div>
                <h1>{info.nome}</h1>
              </div>
              <div>
                <audio controls id='audio'></audio>
              </div>
              <br />
              <div className='start-content-info-content-grid'>
                {Object.keys(citta.address).map((i,n)=>{
                  return(
                    <div key={n}><small>{i}</small>: <big>{citta.address[i]}</big></div>
                  )
                })}
              </div>
            </div>
            <br />
            <big>Summary</big>
            <div className='start-content-info-content-summary' id='summary' onClick={()=>openTextOSummary("summary")}>
              <div>{info.info.map(i=>i.riassunto+" ").join(' ')}</div>
            </div>
            <br />
            <big>Text</big>
            <div className='start-content-info-content-text' id='text' onClick={()=>openTextOSummary("text")}>
              <div>{info.info.map(i=>i.testo+" ").join(' ')}</div>
            </div>
            <br />
            <big>Audio</big>
            <div className='start-content-info-content-list'>
              <div className='start-content-info-content-list-item' onClick={()=>leggiTesto(info.info.map(i=>i.riassunto).join(' '),"Summary")}>
                <div className='start-content-info-content-list-item-svg'>
                  <svg viewBox="0 0 16 16">
                    <path d="M8.5 2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-1 0v-11a.5.5 0 0 1 .5-.5m-2 2a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m4 0a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m-6 1.5A.5.5 0 0 1 5 6v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m8 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m-10 1A.5.5 0 0 1 3 7v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5m12 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5"/>
                  </svg>
                </div>
                <div>Summary</div>
              </div>
              {info.info.map((i,n)=>{
                return(
                  <div key={n} className='start-content-info-content-list-item' onClick={()=>leggiTesto(i.testo,i.titolo)}>
                    <div className='start-content-info-content-list-item-svg'>
                      <svg viewBox="0 0 16 16">
                        <path d="M8.5 2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-1 0v-11a.5.5 0 0 1 .5-.5m-2 2a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m4 0a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m-6 1.5A.5.5 0 0 1 5 6v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m8 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m-10 1A.5.5 0 0 1 3 7v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5m12 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5"/>
                      </svg>
                    </div>
                    <div>{i.titolo}</div>
                  </div>
                )
              })}
            </div>
            <br />
            <small><a href={"https://it.wikipedia.org/wiki/"+info.nome} target="_blank" rel="noreferrer">By Wikipedia</a></small>
          </div>:info&&result&&result.filter(i=>i.tags.name===info.nome).length>0&&<div className='start-content-info-content' id='resizableDiv-info-content'>
            <div className='start-content-info-content-title'>
              {info.info[0]&&info.info[0].img&&info.info[0].img.length>0&&<div className='start-content-info-content-title-img'>
                {info.info[0].img.map((i,n)=>{
                  return(
                    <img key={n} src={i} alt={"img-"+info.nome} />
                  )
                })}
              </div>}
              <div>
                <h1>{info.nome}</h1>
              </div>
              <div>
                <audio controls id='audio'></audio>
              </div>
              <br />
              <div className='start-content-info-content-grid'>
                <div><small>coordinates:</small> <big>{result.filter(i=>i.tags.name===info.nome)[0].bounds?(result.filter(i=>i.tags.name===info.nome)[0].bounds.maxlat+result.filter(i=>i.tags.name===info.nome)[0].bounds.minlat)/2:result.filter(i=>i.tags.name===info.nome)[0].lat}, {result.filter(i=>i.tags.name===info.nome)[0].bounds?(result.filter(i=>i.tags.name===info.nome)[0].bounds.maxlon+result.filter(i=>i.tags.name===info.nome)[0].bounds.minlon)/2:result.filter(i=>i.tags.name===info.nome)[0].lon}</big></div>
                {Object.keys(result.filter(i=>i.tags.name===info.nome)[0].tags).map((i,n)=>{
                  if(Object.keys(result.filter(i=>i.tags.name===info.nome)[0].tags)[n]!=="wikidata"&&Object.keys(result.filter(i=>i.tags.name===info.nome)[0].tags)[n]!=="wikipedia"&&!Object.keys(result.filter(i=>i.tags.name===info.nome)[0].tags)[n].includes("name")&&Object.keys(result.filter(i=>i.tags.name===info.nome)[0].tags)[n]!=="website"&&Object.keys(result.filter(i=>i.tags.name===info.nome)[0].tags)[n]!=="phone"){
                    if(Object.keys(result.filter(i=>i.tags.name===info.nome)[0].tags)[n].split(":")[1]){
                      return(
                        <div key={n}><small>{Object.keys(result.filter(i=>i.tags.name===info.nome)[0].tags)[n].split(":")[1]}</small>: <big>{result.filter(i=>i.tags.name===info.nome)[0].tags[i]}</big></div>
                      )
                    }else{
                      return(
                        <div key={n}><small>{Object.keys(result.filter(i=>i.tags.name===info.nome)[0].tags)[n].split(":")}</small>: <big>{result.filter(i=>i.tags.name===info.nome)[0].tags[i]}</big></div>
                      )
                    }
                  }else if(Object.keys(result.filter(i=>i.tags.name===info.nome)[0].tags)[n]==="website"){
                    return(
                      <div key={n}><small>{Object.keys(result.filter(i=>i.tags.name===info.nome)[0].tags)[n]}</small>: <a href={result.filter(i=>i.tags.name===info.nome)[0].tags[i]} target='_blank' rel="noreferrer">{result.filter(i=>i.tags.name===info.nome)[0].tags[i]}</a></div>
                    )
                  }else if(Object.keys(result.filter(i=>i.tags.name===info.nome)[0].tags)[n]==="phone"){
                    return(
                      <div key={n}><small>{Object.keys(result.filter(i=>i.tags.name===info.nome)[0].tags)[n]}</small>: <a href={"tel:"+result.filter(i=>i.tags.name===info.nome)[0].tags[i]} target='_blank' rel="noreferrer">{result.filter(i=>i.tags.name===info.nome)[0].tags[i]}</a></div>
                    )
                  }
                })}
              </div>
            </div>
            <br />
            <big>Summary</big>
            <div className='start-content-info-content-summary' id='summary' onClick={()=>openTextOSummary("summary")}>
              <div>{info.info&&info.info.map(i=>i.riassunto+" ").join(' ')}</div>
            </div>
            <br />
            <big>Text</big>
            <div className='start-content-info-content-text' id='text' onClick={()=>openTextOSummary("text")}>
              <div>{info.info.map(i=>i.testo+" ").join(' ')}</div>
            </div>
            <br />
            <big>Audio</big>
            <div className='start-content-info-content-list'>
              <div className='start-content-info-content-list-item' onClick={()=>leggiTesto(info.info.map(i=>i.riassunto).join(' '),"Summary")}>
                <div className='start-content-info-content-list-item-svg'>
                  <svg viewBox="0 0 16 16">
                    <path d="M8.5 2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-1 0v-11a.5.5 0 0 1 .5-.5m-2 2a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m4 0a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m-6 1.5A.5.5 0 0 1 5 6v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m8 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m-10 1A.5.5 0 0 1 3 7v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5m12 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5"/>
                  </svg>
                </div>
                <div>Summary</div>
              </div>
              {info&&info.info.map((i,n)=>{
                return(
                  <div key={n} className='start-content-info-content-list-item' onClick={()=>leggiTesto(i.testo,i.titolo)}>
                    <div className='start-content-info-content-list-item-svg'>
                      <svg viewBox="0 0 16 16">
                        <path d="M8.5 2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-1 0v-11a.5.5 0 0 1 .5-.5m-2 2a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m4 0a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m-6 1.5A.5.5 0 0 1 5 6v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m8 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m-10 1A.5.5 0 0 1 3 7v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5m12 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5"/>
                      </svg>
                    </div>
                    <div>{i.titolo}</div>
                  </div>
                )
              })}
            </div>
            <br />
            {/*<big>Video</big>
            <div className='start-content-info-content-list'>
              <div className='start-content-info-content-list-item' onClick={()=>vediTesto(info.info.map(i=>i.riassunto).join(' '),"Summary",info.info[0].img)}>
                <div className='start-content-info-content-list-item-svg'>
                  <svg viewBox="0 0 16 16">
                    <path d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z"/>
                  </svg>
                </div>
                <div>Summary</div>
              </div>
              {info&&info.info.map((i,n)=>{
                return(
                  <div key={n} className='start-content-info-content-list-item' onClick={()=>vediTesto(i.testo,i.titolo,i.img)}>
                    <div className='start-content-info-content-list-item-svg'>
                      <svg viewBox="0 0 16 16">
                        <path d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z"/>
                      </svg>
                    </div>
                    <div>{i.titolo}</div>
                  </div>
                )
              })}
            </div>
            <br />
            <big>Written by Users</big>
            <div className='start-content-info-content-submitted'>
              {info.submitted>0?info.submitted.map((i,n)=>{
                return(
                  <div className='start-content-info-content-submitted-item' key={n}>
                    <div style={{fontWeight:"700"}}>{i.utente}</div>
                    <div>{i.text}</div>
                  </div>
                )
              }):<div>Nothing for now, you can be the first!</div>}
            </div>
            <br />
            <div className='start-content-info-content-utenteActions'>
              <div>Help us improve our contents</div>
              <div className='start-content-info-content-utenteActions-buttons' onClick={()=>{
                if(utente){
                  if(!write)setWrite(true)
                  else setWrite(false)
                }else{
                  popup("You must be logged!","To do this action you must be registered <a href='/login' target='_blank'>Login</a>")
                }
              }}>
                <div className='start-content-info-content-utenteActions-buttons-item'>
                  <svg viewBox="0 0 16 16">
                    <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4z"/>
                    <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5m0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0"/>
                  </svg>
                </div>
                <div className='start-content-info-content-utenteActions-buttons-item'>
                  <svg viewBox="0 0 16 16">
                    <path d="M2.5 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1zm10.113-5.373a7 7 0 0 0-.445-.275l.21-.352q.183.111.452.287.27.176.51.428.234.246.398.562.164.31.164.692 0 .54-.216.873-.217.328-.721.328-.322 0-.504-.211a.7.7 0 0 1-.188-.463q0-.345.211-.521.205-.182.569-.182h.281a1.7 1.7 0 0 0-.123-.498 1.4 1.4 0 0 0-.252-.37 2 2 0 0 0-.346-.298m-2.168 0A7 7 0 0 0 10 6.352L10.21 6q.183.111.452.287.27.176.51.428.234.246.398.562.164.31.164.692 0 .54-.216.873-.217.328-.721.328-.322 0-.504-.211a.7.7 0 0 1-.188-.463q0-.345.211-.521.206-.182.569-.182h.281a1.8 1.8 0 0 0-.117-.492 1.4 1.4 0 0 0-.258-.375 2 2 0 0 0-.346-.3z"/>
                  </svg>
                </div>
              </div>
              {write&&<div className='start-content-info-content-utenteActions-form'>
                <form onSubmit={(e)=>{
                  e.preventDefault()
                  submit(document.getElementById("textarea").value,document.getElementById("filearea").value,info.id)
                }}>
                  <textarea id="textarea"></textarea>
                  <input id='filearea' type="file"/>
                  <button>Save</button>
                </form>
              </div>}
            </div>*/}
            <br />
            <small><a href={"https://it.wikipedia.org/wiki/"+info.nome} target="_blank" rel="noreferrer">By Wikipedia</a></small>
          </div>}
        </div>
      </div>
    </div>
  );
}

export default Start