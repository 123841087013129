import React from 'react'
import "./about.css"

const About = () => {
    document.title="Who | Gita"
    function reveal1() {
        var reveals=document.getElementsByClassName("about-paragraph");
        for(var i=0;i<reveals.length;i++){
          var windowHeight=window.innerHeight;
          var elementTop=reveals[i].getBoundingClientRect().top;
          var elementVisible=150;
          if(elementTop<windowHeight-elementVisible){
            reveals[i].classList.add("active");
          }else{
            reveals[i].classList.remove("active");
          }
        }
    }
    window.addEventListener("scroll", reveal1);
  return (
    <div className='about'>
        <div className='about-paragraph' style={{opacity:"1"}}>
            <div className='about-paragraph-c'>
                <div className='about-paragraph-c-title'>
                    <h1 style={{color:"#FFE2C7"}}>Who</h1>
                </div>
                <p>Welcome to Gita, a young and entirely Italian company, born in the heart of a land that is synonymous with unparalleled beauty and culture. Italy, with its extraordinary artistic richness and its thousand-year history, is the perfect place to bring a project like ours to life.</p>
            </div>
            <div className='about-paragraph-i'>
                <img src="https://images.pexels.com/photos/753639/pexels-photo-753639.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
            </div>
        </div>
        <br />
        <div className='about-paragraph'>
            <div className='about-paragraph-c'>
                <div className='about-paragraph-c-title'>
                    <div style={{color:"#D7F3F6"}}>Why</div>
                </div>
                <p>We were born with a clear and ambitious idea: to make the treasures of humanity accessible to everyone, easily and free of charge. We are committed to breaking down the barriers that often make access to places and monuments of inestimable cultural and historical value difficult. Thanks to modern technology, we have made it possible to virtually explore places that might otherwise be out of reach for many people. We are driven by a passion for sharing knowledge and beauty, and believe that offering this service for free is our way of giving back to the global community. We want to inspire curiosity, stimulate the mind and foster understanding and appreciation of the diverse cultures and traditions that enrich our planet.</p>
            </div>
            <div className='about-paragraph-i'>
                <img src="https://images.pexels.com/photos/1796706/pexels-photo-1796706.jpeg?auto=compress&cs=tinysrgb&w=800" alt="" />
            </div>
        </div>
        <br />
        <div className='about-paragraph'>
            <div className='about-paragraph-c'>
                <div className='about-paragraph-c-title'>
                    <div style={{color:"#B0ADE9"}}>Goals</div>
                </div>
                <p>Our first goal is to grow as a technology company, always remaining at the forefront of technology and innovation. We want to continue to develop and implement new features, improve the usability and user experience of our app, and stay up to date on the latest technology trends. We are committed to investing in human resources and the training of our team to ensure constant and sustainable growth in the field of technology. Secondly, we aim to grow in terms of content quality. We want to offer our users increasingly rich, informative and engaging content. We are committed to collaborating with experts in the fields of tourism, art history and culture to ensure the accuracy and authenticity of the information provided on our platform. We want to be recognized as a reliable and authoritative source of tourism content, capable of satisfying the needs and expectations of all our users.</p>
            </div>
            <div className='about-paragraph-i'>
                <img src="https://images.pexels.com/photos/2653362/pexels-photo-2653362.jpeg?auto=compress&cs=tinysrgb&w=800" alt="" />
            </div>
        </div>
    </div>
  )
}

export default About