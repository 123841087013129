import React from 'react'
import "./pageError.css"

const PageError = () => {
  return (
    <div className='pageError'>
        <div className='pageError-title'>
            <div>Error 404</div>
        </div>
        <br />
        <br />
        <div className='pageError-content'>
            <div className='pageError-content-text'>
                <div>Non-existent page. Back to where you can actually use the app.</div>
            </div>
            <br />
            <br />
            <div className='pageError-content-buttons'>
                <button onClick={()=>window.location.href="/"}>Home</button>
            </div>
        </div>
    </div>
  )
}

export default PageError